<template>
  <v-container class="py-5">
    <v-row align="center" justify="center" class="transparent pt-5">
      <v-card elevation="0" width="1100" class="transparent mb-5">
        <v-card-text>
          <h1 class="headline mb-4 black--text">{{ $t('menu.myVideoconsultations') }}</h1>

          <div v-if="isLoading" class="text-center">
            <v-progress-circular indeterminate color="primary" />
          </div>

          <v-card v-for="item in appointments" :key="item.id" outlined class="mb-5">
            <v-card v-if="isMobileVersion" @click="action(item.btnActionClick, item)">
              <v-card-text>
                <v-row dense>
                  <v-col cols="8" md="5">
                    <div class="d-flex flex-column flex-md-row justify-space-between align-md-center">
                      <div class="subtitle-1 font-weight-bold">
                        {{ item.professional.name }}
                      </div>
                      <div class="caption text-no-wrap">
                        <v-icon small light class="mr-1">mdi-clock-outline</v-icon>
                        {{ item.startAt | moment('LLLL') }}
                      </div>
                      <div class="caption text-no-wrap">
                        {{ $t('videoconsulta.codigo') }}: {{ item.videoconsultation.code }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="4" md="4" align="end">
                    <div class="caption text-no-wrap">
                      <v-chip :class="item.statusColor + '--text'" x-small color="grey lighten-4">
                        {{ item.statusText }}
                      </v-chip>
                    </div>
                    <div class="mt-6">
                      <v-btn
                        v-if="item.btnActionText !== ''"
                        v-tooltip="item.btnActionTooltip"
                        small
                        icon
                        color="primary"
                        @click="action(item.btnActionClick, item)"
                      >
                        <v-icon>{{ item.btnActionIcon }}</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-list v-else>
              <v-list-item @click="action(item.btnActionClick, item)">
                <v-list-item-avatar>
                  <v-img :src="item.professional.image" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.startAt | moment('LLLL') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.professional.name }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content v-if="item.confirmed && item.videoconsultation !== null">
                  <v-list-item-subtitle>
                    {{ $t('videoconsulta.codigo') }}: {{ item.videoconsultation.code }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-chip :class="item.statusColor + '--text'" color="grey lighten-4">{{ item.statusText }}</v-chip>
                </v-list-item-icon>
                <v-list-item-action>
                  <v-btn
                    v-if="item.btnActionText !== ''"
                    v-tooltip="item.btnActionTooltip"
                    depressed
                    color="primary"
                    @click="action(item.btnActionClick, item)"
                  >
                    {{ item.btnActionText }}
                  </v-btn>
                  <v-btn v-else v-tooltip="item.btnActionTooltip" icon>
                    <v-icon color="grey lighten-1">{{ item.btnActionIcon }}</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
          <div
            v-if="!isLoading && (!appointments || !appointments.length)"
            class="py-5 text-center grey-darken-1--text"
          >
            {{ $t('appointments.not_have_pending') }}
          </div>

          <template v-if="totalResults">
            <div class="mt-3 text-center">
              <v-pagination
                v-if="totalPages > 1"
                v-model="currentPage"
                :length="totalPages"
                text
                :total-visible="6"
                circle
                color="primary"
                @input="changePage"
              />
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import Alerts from '@/mixins/Alerts';
import { getAppointmentsUrl, getHeader, urlPatient, displayNameEnv } from '../../config/config';

export default {
  title: displayNameEnv + ' - ' + vm.$t('menu.myVideoconsultations'),
  mixins: [Alerts],
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      perPage: 20,
      totalPages: null,
      totalResults: null,
      appointments: [],
    };
  },
  computed: {
    isMobileVersion() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    this.loadAppointments();
  },
  methods: {
    changePage(page) {
      this.appointments = [];
      this.currentPage = page;
      this.loadAppointments();
    },
    loadAppointments() {
      this.isLoading = true;
      this.$http
        .get(urlPatient(getAppointmentsUrl, this.perPage, this.currentPage), { headers: getHeader() })
        .then(res => {
          this.appointments = this.setData(res.body.data);
          this.totalResults = res.body._meta.total;
          this.totalPages = Math.ceil(this.totalResults / this.perPage);
        })
        .catch(err => {
          this.$log.error(err);
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    setData(appointments) {
      appointments.forEach(item => {
        if (!item.paid) {
          item.statusColor = 'orange';
          item.statusText = this.$t('videoconsultations.pending_payment');
          item.btnActionIcon = 'mdi-credit-card-outline';
          item.btnActionTooltip = this.$t('videoconsultations.pay_video');
          item.btnActionText = this.$t('videoconsultations.pay');
          item.btnActionClick = 'gotopay';
        } else if (item.confirmed) {
          item.statusColor = 'green';
          item.statusText = this.$t('videoconsultations.confirmed');
          item.btnActionIcon = 'mdi-video-wireless-outline';
          item.btnActionTooltip = this.$t('videoconsultations.go_to_video');
          item.btnActionText = this.$t('videoconsultations.to_access');
          item.btnActionClick = 'gotovideo';
        } else {
          item.statusColor = 'red';
          item.statusText = this.$t('videoconsultations.pending_confirm');
          item.btnActionIcon = 'mdi-information-outline';
          item.btnActionText = '';
          item.btnActionTooltip = this.$t('videoconsultations.pending_confirm_explain');
        }
      });
      return appointments;
    },

    action(option, item) {
      if (option === 'gotovideo') {
        window.open(item.videoconsultation.url, '_blank');
      } else if (option === 'gotopay') {
        this.$router.push('/pay/' + item.professional.id + '/appointment/' + item.id);
      }
    },
  },
};
</script>
